import React from 'react';
import { Container } from 'components/common';
import HomeLink from './HomeLink';
import NavbarLinks from '../NavbarLinks';
import { Wrapper } from './styles';

const Navbar = () => (
  <Wrapper as={Container}>
    <HomeLink />
    <NavbarLinks desktop />
  </Wrapper>
);

export default Navbar;
