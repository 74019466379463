import styled from 'styled-components';
import midBlob from 'assets/illustrations/mid-blob.svg';

export const Wrapper = styled.div`
  background-image: url(${midBlob});
  background-size: contain;
  background-position: left top;
  background-repeat: no-repeat;
`;

export const BadgesWrapper = styled.div`
  padding: 4rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

export const Details = styled.div`
  flex: 1;
  padding-left: 4rem;

  @media (max-width: 960px) {
    padding-left: unset;
    width: 100%;
  }

  h1 {
    margin-bottom: 2rem;
    font-size: 26pt;
    color: #212121;
  }
`;

export const Thumbnail = styled.div`
  flex: 1;
  transform: scaleX(-1);

  @media (max-width: 960px) {
    width: 100%;
    margin-bottom: 2rem;
  }

  img {
    width: 100%;
  }
`;

export const BadgeList = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  margin: 2em 0;
`;

export const Badge = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  opacity: 0.7;
  padding: 0.5rem;

  & > * {
    margin: 0;
  }
`;

export const BadgeIcon = styled.div`
  padding: 1.5rem 0;
`;
