import React from 'react';
import { Container } from 'components/common';
import LetUsKnow from 'components/landing/Badges/LetUsKnow';
import { Wrapper, Flex, Details } from './styles';
import { defaultTitle } from '../../../../site-config';

export const Footer = ({ isHome }) => (
  <Wrapper>
    <Flex as={Container}>
      <Details>
        <h2>{defaultTitle}</h2>
        <span>{`© All rights reserved ${new Date().getFullYear()}`}</span>
        <span>
          {' '}
          | 
{' '}
<a href='/legal/terms_of_service.txt'>Terms of Service</a>
        </span>
        <span>
          {' '}
          |
{' '}
          <a href='/legal/end_user_license_agreement.txt'>
            {' '}
            End User Agreement
{' '}
          </a>
        </span>
{' '}
      </Details>
      {isHome ? null : <LetUsKnow />}
    </Flex>
  </Wrapper>
);
