import React from 'react';
import { Link } from 'gatsby';
import { defaultTitle } from '../../../../../../site-config';

const HomeLink = () => (
  <Link to='/' style={{ fontSize: '1.5rem', textDecoration: 'none' }}>
    {defaultTitle}
  </Link>
);

export default HomeLink;
