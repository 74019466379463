import styled from 'styled-components';

export const ButtonWrapper = styled.div`
  display: flex;
  margin: 1rem;

  > * {
    box-shadow: 2px 2px 5px gray;
  }
`;

export const Pricing = styled.h2`
  padding: 1rem;
  font-weight: normal;
  margin: 0;
  color: #6c63ff;
`;

export const Error = styled.div`
  padding-right: 1rem;
  color: red;
`;

export const PriceWrapper = styled.div`
  background-color: #efeeff;
  display: flex;
  align-items: center;
`;
