import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Link } from 'gatsby';
import { Button } from 'components/common';
import { BadgeList } from '../styles';

const LetUsKnow = ({ isHome }) => {
  const anchor = '#contact';
  const linkProps = isHome
    ? {
        as: AnchorLink,
        href: anchor
      }
    : {
        as: Link,
        to: `/${anchor}`
      };

  return (
    <BadgeList>
      <h4 style={{ margin: '1rem' }}>Want a free quote?</h4>
      <Button {...linkProps}>Let us know</Button>
    </BadgeList>
  );
};

export default LetUsKnow;
