module.exports = {
  defaultTitle: 'Mr. Robot',
  logo: 'https://www.mrrobotllc.com/favicon.png',
  author: 'Gregory Hilston',
  url: 'https://www.mrrobotllc.com',
  legalName: 'Gregory Hilston',
  defaultDescription: 'Mr. Robot delivers that hard-to-reach data.',
  googleAnalyticsID: 'UA-159731751-1',
  themeColor: '#6b63ff',
  backgroundColor: '#6b63ff',
  address: {
    city: 'Merrimack',
    region: 'Region',
    country: 'United States',
    zipCode: '03054'
  },
  contact: {
    email: 'admin@mrrobotllc.com',
    phone: '6036610401'
  },
  foundingDate: '2020',
  stripePublicKey: 'pk_live_NnfGpqk3rhhVFX92IgzazisX00CWf0AhoG'
};
