import React from 'react';
import { Button } from '../Button';
import { Pricing, ButtonWrapper, PriceWrapper } from './styles';

export const SampleButton = ({ sampleFileName }) => {
  const cornerRadius = 14;
  return (
    <ButtonWrapper>
      <PriceWrapper
        style={{ borderRadius: `${cornerRadius}px 0 0 ${cornerRadius}px` }}
      >
        <Pricing>$0</Pricing>
      </PriceWrapper>
      <Button
        as='a'
        style={{
          borderRadius: `0 ${cornerRadius}px ${cornerRadius}px 0`
        }}
        href={`/samples/${sampleFileName}`}
        download={sampleFileName}
      >
        Free Sample
      </Button>
    </ButtonWrapper>
  );
};
