import React from 'react';
import { Footer } from 'components/theme';
import { Global, Viewport } from './styles';
import './fonts.css';

export const Layout = ({ isHome, children }) => (
  <Viewport>
    <Global />
    {children}
    <Footer isHome={isHome} />
  </Viewport>
);
