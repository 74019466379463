import React, { useEffect, useRef, useState } from 'react';
import { Button } from '../Button';
import { Pricing, ButtonWrapper, PriceWrapper } from './styles';
import { stripePublicKey } from '../../../../site-config';

export const CheckoutButton = ({ price, sku, baseUrl, currentUrl }) => {
  const [isDisabled, setDisabled] = useState(false);
  const stripeRef = useRef(null);
  useEffect(() => {
    try {
      const stripe = window.Stripe(stripePublicKey);
      stripeRef.current = stripe;
    } catch (e) {
      setDisabled(true);
    }
  }, []);

  const handleCheckout = () => {
    stripeRef.current
      .redirectToCheckout({
        items: [
          {
            sku,
            quantity: 1
          }
        ],
        successUrl: `${baseUrl}/success`,
        cancelUrl: currentUrl
      })
      .then(result => {
        if (result.error) {
          setDisabled(true);
          console.error(result.error); // eslint-disable-line
        }
      });
  };

  const cornerRadius = 14;
  return (
    <ButtonWrapper>
      <PriceWrapper
        style={{ borderRadius: `${cornerRadius}px 0 0 ${cornerRadius}px` }}
      >
        <Pricing>{price}</Pricing>
      </PriceWrapper>
      <Button
        style={{
          borderRadius: `0 ${cornerRadius}px ${cornerRadius}px 0`
        }}
        role='link'
        onClick={handleCheckout}
        disabled={isDisabled}
        secondary
      >
        {isDisabled ? 'Payment service issue' : 'Buy now'}
      </Button>
    </ButtonWrapper>
  );
};
