import footerIllustration from 'assets/illustrations/footer.svg';
import styled from 'styled-components';

export const Header = styled.div`
  padding-top: 12rem;
  background-image: url(${footerIllustration});
  background-size: cover;
  background-position: right top;
  background-repeat: no-repeat;
  transform: scale(-1, -1);
  margin-bottom: 2rem;

  @media (max-width: 1960px) {
    padding-top: 6rem;
  }
`;

export const HeaderContent = styled.div`
  transform: scale(-1, -1);
`;

export const PostBody = styled.div`
  figcaption {
    text-align: center;
    font-style: italic;
  }
  table {
    table-layout: fixed;
  }
`;

export const Nav = styled.nav`
  width: 100%;
`;

export const NavList = styled.ul`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
`;
